import React from "react"
import PropTypes, { bool } from "prop-types"
import { compose, graphql } from "react-apollo"

import serviceStatsQuery from "../../graphql/serviceStatsQuery.graphql"
import servicesQuery from "../../graphql/services/servicesQuery.graphql"
import servicesSharedQuery from "../../graphql/services/servicesSharedQuery.graphql"
import ServicesCountGridWidget from "./ServicesCountGridWidget"
import { DashboardPanel } from "./dashboardPanel"

import { useI18n } from "../../internationalization/i18n"

const getCount = (data, status) => {
  const selectedData = data.find(i => i.status === status)
  return selectedData ? selectedData.count : 0
}

const getStatsData = data => {
  if (!data) return { total: 0 }
  const active = getCount(data, "active")
  const limited = getCount(data, "limited")
  const stopped = getCount(data, "stopped")
  const total = active + limited + stopped
  return { total, active, limited, stopped }
}

const getPbxCount = pbxService =>
  pbxService.services && pbxService.services.length >= 1 ? pbxService.services.length : 0

const getServicesShared = servicesShared =>
  servicesShared.servicesShared && servicesShared.servicesShared.length >= 1
    ? servicesShared.servicesShared.length
    : 0

const ServicesWidget = ({
  customerId,
  hideAddButton,
  serviceStats,
  pbxService,
  pbxVisible,
  servicesShared,
  sharedServicesVisible,
}) => {
  const msg = useI18n().messages.components.servicesWidget
  return (
    <DashboardPanel
      linkToDetail={`/services?type=all${customerId ? `&customerId=${customerId}` : ""}`}
      linkAddNew={
        hideAddButton ? "" : `/serviceSelection${customerId ? `?customerId=${customerId}` : ""}`
      }
      icon="grid"
      title={msg.title}
      id="services"
      loading={serviceStats.loading || serviceStats.loading}
      error={pbxVisible && (pbxService.error || pbxService.error)}
    >
      {!serviceStats.loading && !serviceStats.error && (
        <ServicesCountGridWidget
          mobileStats={getStatsData(serviceStats.mobileStats)}
          faxStats={getStatsData(serviceStats.faxStats)}
          voiceStats={getStatsData(serviceStats.voiceStats)}
          connectorsStats={
            !serviceStats.loading &&
            !serviceStats.error && { total: serviceStats.connectors.length }
          }
          pbxCount={
            pbxVisible && !pbxService.loading && !pbxService.error
              ? getPbxCount(pbxService)
              : undefined
          }
          servicesSharedCount={
            sharedServicesVisible && !servicesShared.loading && !servicesShared.error
              ? getServicesShared(servicesShared)
              : undefined
          }
          pbxId={
            pbxVisible &&
            !pbxService.loading &&
            !pbxService.error &&
            pbxService.services &&
            pbxService.services.length >= 1
              ? pbxService.services[0].service.id
              : undefined
          }
          helpdeskCount={
            (!serviceStats.loading &&
              !serviceStats.error &&
              serviceStats.helpdesks &&
              serviceStats.helpdesks.count) ||
            0
          }
          customerId={customerId}
          labels={msg.labels}
        />
      )}
    </DashboardPanel>
  )
}

ServicesWidget.defaultProps = {
  customerId: "",
  hideAddButton: false,
  pbxVisible: false,
  sharedServicesVisible: false,
}

ServicesWidget.propTypes = {
  customerId: PropTypes.string,
  hideAddButton: PropTypes.bool,
  pbxVisible: PropTypes.bool,
  serviceStats: PropTypes.object,
  pbxService: PropTypes.object,
  servicesShared: PropTypes.object,
  sharedServicesVisible: bool,
}

export default compose(
  graphql(serviceStatsQuery, {
    name: "serviceStats",
    options: ({ customerId }) => ({
      variables: {
        customerId: customerId ? `&customerId=${customerId}` : "",
        customerIdWithoutQuery: customerId || 0,
      },
    }),
  }),
  graphql(servicesQuery, {
    name: "pbxService",
    skip: ({ customerId }) => !customerId,
    options: ({ customerId }) => ({
      variables: {
        pathBuilder: () => `services/fulltext?customerId=${customerId || 0}&type=pbx`,
      },
    }),
  }),
  graphql(servicesSharedQuery, {
    name: "servicesShared",
    skip: ({ customerId }) => !customerId,
    options: ({ customerId }) => ({
      variables: {
        pathBuilder: () => `services/shared?customerId=${customerId || 0}`,
      },
    }),
  }),
)(ServicesWidget)
