/** Created by Lenka Hubáčková on 02/09/2019. */
import React from "react"
import PropTypes from "prop-types"
import { useChart } from "../../utils/hooks"

const PieChart = ({ data, labels, title, options, backgroundColors }) => {
  const graphOptions = {
    cutoutPercentage: 50,
    title: {
      display: title,
      text: title,
    },
    ...options,
  }
  const graphData = {
    datasets: [
      {
        data: data,
        backgroundColor: backgroundColors,
      },
    ],
    labels: labels,
  }
  const graphContent = useChart({
    type: "pie",
    options: graphOptions,
    data: graphData,
  })

  return (
    <div style={{ position: "relative", width: "100%", height: "15rem", margin: "0 auto" }}>
      <canvas ref={graphContent} />
    </div>
  )
}

export default PieChart

PieChart.propTypes = {
  data: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  title: PropTypes.string,
  options: PropTypes.object,
  backgroundColors: PropTypes.array,
}

PieChart.defaultProps = {
  backgroundColors: ["#AFCB08", "#313EFC", "#FC9D31", "#E5DE0B", "#B63EF2"],
}
