/** Created by Lenka Hubáčková on 2019-01-03. */
import React from "react"
import { Query } from "react-apollo"

import { useI18n } from "../../internationalization/i18n"

import Top5 from "./Top5"
import { DashboardPanel } from "./dashboardPanel"
import topCustomers from "../../graphql/reports/topCustomersQuery.graphql"

// const mockData = [
//   { id: "34870", customerName: "JKL TEST SIP trunk", price: 12000 },
//   { id: "28331", customerName: "Firma s.r.o.", price: 4881.42 },
//   { id: "32040", customerName: "Pod Parukářkou", price: 500.99 },
//   { id: "27672", customerName: "Marek Mikuš", price: 3219 },
//   { id: "31201", customerName: "Pavel Píštěk", price: 13942 },
// ]

const Top5Widget = () => {
  const msg = useI18n().messages.dashboard
  return (
    <Query query={topCustomers} variables={{ paymentType: "both", monthOffset: 1 }}>
      {({ loading, error, data }) => (
        <DashboardPanel
          linkToDetail="/invoices"
          icon="credit-card"
          title={msg.top5}
          id="invoices"
          loading={loading}
          error={error}
          style={{ position: "relative" }}
        >
          {data && <Top5 data={data.topCustomers} />}
        </DashboardPanel>
      )}
    </Query>
  )
}

export default Top5Widget
