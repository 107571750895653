import React from "react"
import PropTypes from "prop-types"
import Link from "../../Link"

import { useI18n } from "../../../../internationalization/i18n"

export const ProvidersGridBreadcrumb = () => {
  const msg = useI18n().messages.components.breadcrumbs
  return <Link to="/providers">{msg.providers}</Link>
}
export const ProviderSettingsBreadcrumb = () => {
  const msg = useI18n().messages.components.breadcrumbs
  return <span>{msg.providerForm}</span>
}

export const ProviderTariffsBreadcrumb = () => {
  const msg = useI18n().messages.components.breadcrumbs
  return <span>{msg.providerTariffs}</span>
}

export const ProviderNameBreadcrumb = ({ providerName }) => <span>{providerName}</span>

export const ProviderNewBreadcrumb = () => {
  const msg = useI18n().messages.components.breadcrumbs
  return <span>{msg.providerNew}</span>
}

ProviderNameBreadcrumb.propTypes = {
  providerName: PropTypes.string,
}

export const ProviderTechnicalSpecificationBreadcrumb = () => {
  const msg = useI18n().messages.components.breadcrumbs
  return <span>{msg.technicalSpecification}</span>
}

export const ProviderFormBreadcrumb = ({ providerName, providerId }) => (
  <Link to={`providerForm?providerId=${providerId}`}>{providerName}</Link>
)

ProviderFormBreadcrumb.propTypes = {
  providerName: PropTypes.string,
  providerId: PropTypes.string,
}

export const ProviderDashboardBreadcrumb = () => {
  const msg = useI18n().messages.components.breadcrumbs
  return <span>{msg.dashboard}</span>
}
