import React from "react"
import PropTypes from "prop-types"
import injectSheet from "react-jss"

const textCountWidgetStyles = {
  textCountWidgetBody: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    textTransform: "lowercase",
    justifyContent: "center",
  },
  textCountSum: {
    fontSize: "3rem",
    lineHeight: "4rem",
  },
  textCountLabel: {
    fontSize: "0.875rem",
    textAlign: "center",
  },
}

const TextCountWidget = ({ total, title, classes, children }) => (
  <div className={classes.textCountWidgetBody}>
    <span className={classes.textCountSum}>{total}</span>
    <span className={classes.textCountLabel}>{title}</span>
    <span>{children}</span>
  </div>
)

TextCountWidget.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  total: PropTypes.oneOfType([PropTypes.oneOf(["-"]), PropTypes.number]).isRequired,
  children: PropTypes.node,
}

TextCountWidget.defaultProps = {
  total: "-",
}

export default injectSheet(textCountWidgetStyles)(TextCountWidget)
