import React from "react"
import PropTypes from "prop-types"
import ServicesCountWidget from "./ServicesCountWidget"
import TextCountWidget from "./TextCountWidget"
import injectSheet from "react-jss"

const servicesCountGridWidgetStyles = {
  servicesCountGrid: {
    display: "grid",
    gridGap: "1rem",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "repeat(2, 1fr)",
    "& div:nth-child(1)": { gridColumn: 1, gridRow: "1 / span 2" },
    "& div:nth-child(2)": { gridColumn: 2, gridRow: 1 },
    "& div:nth-child(3)": { gridColumn: 2, gridRow: 2 },
    "& div:nth-child(4)": { gridColumn: 3, gridRow: 1 },
    "& div:nth-child(5)": { gridColumn: 3, gridRow: 2 },
  },
}

const returnTitle = (msg, count) => {
  if (count === undefined) return
  const totalCount =
    typeof count === "number"
      ? count
      : (count.active || 0) + (count.limited || 0) + (count.stopped || 0)
  return totalCount === 1 ? msg.one : totalCount <= 4 && totalCount > 0 ? msg.toFive : msg.more
}

const ServicesCountGridWidget = ({
  mobileStats,
  faxStats,
  voiceStats,
  connectorsStats,
  customerId,
  labels,
  classes,
  pbxCount,
  helpdeskCount,
  servicesSharedCount,
  pbxId,
}) => {
  const customerQuery = customerId ? `&customerId=${customerId}` : ""
  const pbxQuery = `customerId=${customerId}&serviceId=${pbxId}`
  const totalCount =
    faxStats.total + voiceStats.total + mobileStats.total + connectorsStats.total + (pbxCount || 0)
  return (
    <div className={classes.servicesCountGrid}>
      <TextCountWidget
        total={totalCount}
        title={returnTitle(
          labels.counts.services,
          faxStats.total + voiceStats.total + mobileStats.total + connectorsStats.total,
        )}
      />
      <ServicesCountWidget
        labels={labels.smallDashboardWidgetLabels}
        link={`/services?type=mobile${customerQuery}`}
        title={returnTitle(labels.counts.mobiles, mobileStats.total)}
        values={mobileStats}
      />
      <ServicesCountWidget
        labels={labels.smallDashboardWidgetLabels}
        link={`services?type=fax${customerQuery}`}
        title={labels.others}
        values={faxStats}
      />
      <ServicesCountWidget
        labels={labels.smallDashboardWidgetLabels}
        link={`/services?type=voice${customerQuery}`}
        title={returnTitle(labels.counts.voips, voiceStats.total)}
        values={voiceStats}
      />
      <div>
        <ServicesCountWidget
          labels={labels.smallDashboardWidgetLabelsConnector}
          link={`/connectors?${customerQuery}`}
          title={returnTitle(labels.counts.connectors, connectorsStats.total)}
          onlyTotal={true}
          values={connectorsStats}
        />
        {pbxCount === 1 && pbxId && (
          <ServicesCountWidget
            link={`/pbxDashboard?${pbxQuery}`}
            title={returnTitle(labels.counts.pbx, pbxCount)}
            onlyTotal={true}
            values={{ total: pbxCount }}
          />
        )}
        {pbxCount > 1 && (
          <ServicesCountWidget
            link={`/services?type=pbx${customerQuery}`}
            title={returnTitle(labels.counts.pbx, pbxCount)}
            onlyTotal={true}
            values={{ total: pbxCount }}
          />
        )}
        {servicesSharedCount >= 1 && (
          <ServicesCountWidget
            link={`/sharedServices?customerId=${customerId}`}
            title={returnTitle(labels.counts.sharedServices, servicesSharedCount)}
            onlyTotal={true}
            values={{ total: servicesSharedCount }}
          />
        )}
        {pbxCount === 0 && (
          <ServicesCountWidget
            title={returnTitle(labels.counts.pbx, pbxCount)}
            onlyTotal={true}
            values={{ total: pbxCount }}
          />
        )}
        {helpdeskCount > 0 && (
          <ServicesCountWidget
            labels={labels.smallDashboardWidgetLabelsConnector}
            link={`/services?type=helpdesk${customerQuery}`}
            title={returnTitle(labels.counts.helpdesks, helpdeskCount)}
            onlyTotal={true}
            values={{ total: helpdeskCount }}
          />
        )}
      </div>
    </div>
  )
}

ServicesCountGridWidget.defaultProps = {
  customerId: "",
  pbxCount: undefined,
  pbxId: undefined,
  servicesSharedCount: undefined,
}

ServicesCountGridWidget.propTypes = {
  customerId: PropTypes.string,
  labels: PropTypes.object,
  mobileStats: PropTypes.object,
  faxStats: PropTypes.object,
  voiceStats: PropTypes.object,
  connectorsStats: PropTypes.object,
  classes: PropTypes.object,
  pbxCount: PropTypes.number,
  helpdeskCount: PropTypes.number,
  pbxId: PropTypes.string,
  servicesSharedCount: PropTypes.number,
}

export default injectSheet(servicesCountGridWidgetStyles)(ServicesCountGridWidget)
