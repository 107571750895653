import React, { useContext } from "react"
import PropTypes from "prop-types"
import Link from "next/link"
import { createUseStyles } from "react-jss"
import { useB2BExported } from "../../../utils/hooks"

import { CookieContext, hasRole, role } from "../../../core"
import Icon from "../../common/Icon"

const useStyles = createUseStyles(theme => ({
  panelHeader: {
    display: "flex",
    height: "3.25rem",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "1rem",
    padding: "0 0.5rem",
    borderBottom: `2px solid ${theme.color.grey50}`,
    cursor: props => (props.linkToDetail ? "pointer" : "default"),
    "&:hover": {
      borderBottom: ({ linkToDetail }) => linkToDetail && `2px solid ${theme.color.theme500}`,
    },
    "&:hover [toDetail=toDetail]": {
      color: ({ linkToDetail }) => linkToDetail && theme.color.theme500,
    },
  },
  title: {
    flex: "1 1 auto",
  },
  icon: {
    marginRight: "1rem",
    display: "flex",
  },
  arrowIcon: {
    color: theme.color.darkGrey,
  },
  addLinkIcon: {
    fontSize: "1.5rem",
    color: theme.color.theme500,
    margin: "0 1rem",
    "&:hover": {
      color: theme.color.theme650,
    },
  },
  absoluteLink: {
    textDecoration: "none",
    color: theme.color.darkGrey,
  },
}))

const DashboardPanelHeader = ({ linkToDetail, linkAddNew, icon, title, id, fullClick }) => {
  const { user } = useContext(CookieContext)
  const isExported = useB2BExported()
  const allowServices =
    isExported || !user
      ? true
      : hasRole(user.roles, [role.SUPERADMIN, role.ADMIN, role.REALIZATION])

  const classes = useStyles({ linkToDetail, linkAddNew })

  const content = (
    <div className={classes.panelHeader}>
      {icon && (
        <div className={classes.icon}>
          <Icon iconType={icon} size={22} />
        </div>
      )}
      <div className={classes.title}>{title}</div>

      {linkAddNew && allowServices && (
        <div className={classes.addLink}>
          <Link href={linkAddNew}>
            <Icon
              className={classes.addLinkIcon}
              iconType="plus-circle"
              id={`add-${id}`}
              data-cy={`add-${id}`}
              size={24}
            />
          </Link>
        </div>
      )}

      {linkToDetail && !fullClick && (
        <div className={classes.detailLink} toDetail="toDetail">
          <Link href={linkToDetail}>
            <Icon
              className={classes.arrowIcon}
              iconType="arrow-right"
              id={id}
              data-cy={id}
              toDetail="toDetail"
              size={17}
            />
          </Link>
        </div>
      )}
    </div>
  )

  if (linkToDetail) {
    return linkToDetail.startsWith("http") ? (
      <a href={linkToDetail} className={classes.absoluteLink} target="_blank">
        {content}
      </a>
    ) : (
      <Link href={linkToDetail}>{content}</Link>
    )
  }
  return content
}

DashboardPanelHeader.propTypes = {
  linkToDetail: PropTypes.string,
  linkAddNew: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  id: PropTypes.string,
  children: PropTypes.node,
  fullClick: PropTypes.bool,
}

DashboardPanelHeader.defaultProps = {
  linkToDetail: null,
  fullClick: false,
}

export default DashboardPanelHeader
