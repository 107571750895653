/** Created by Lenka Hubáčková on 2019-02-09. */
import React from "react"
import PropTypes from "prop-types"
import Router from "next/router"

import { useI18n } from "../../internationalization/i18n"

import PieChart from "../graphs/PieChart"
import TextCountWidget from "./TextCountWidget"

const Top5 = ({ data }) => {
  const msg = useI18n().messages.dashboard

  const top5Prices = data.map(topCustomer => Math.round(topCustomer.price))
  const top5Labels = data.map(topCustomer =>
    topCustomer.customerName.length > 20
      ? `${topCustomer.customerName.slice(0, 20)}...`
      : topCustomer.customerName,
  )
  const top5Ids = data.map((topCustomer, index) => ({
    id: topCustomer.id,
    name: top5Labels[index],
  }))
  const options = {
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const dataset = data.datasets[tooltipItem.datasetIndex]
          return `${dataset.data[tooltipItem.index]},-`
        },
        title: (tooltipItem, data) => data.labels[tooltipItem[0].index],
      },
    },
    legend: {
      position: "bottom",
      padding: 10,
      onClick: (e, i) => {
        const customerId = top5Ids.find(customer => customer.name === i.text).id
        Router.push({ pathname: "/customerDashboard", query: { customerId } })
      },
      labels: {
        fontSize: 12,
        boxWidth: 15,
      },
    },
    maintainAspectRatio: false,
  }

  if (!top5Prices.length || Number(top5Prices[0]) === 0)
    return <TextCountWidget total={0} title={msg.top5WidgetTitle} />
  return <PieChart data={top5Prices} labels={top5Labels} options={options} />
}

export default Top5

Top5.propTypes = { data: PropTypes.array }
