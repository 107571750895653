import React from "react"
import PropTypes from "prop-types"
import Link from "../common/Link"
import injectSheet from "react-jss"

const servicesCountWidgetStyles = {
  countWidgetBody: {
    color: "#979898",
  },
  totalRow: {
    fontSize: "1rem",
  },
  totalRowWithLink: {
    color: "black",
  },
}

const ServicesCountWidget = ({ title, values = {}, labels, onlyTotal = false, link, classes }) => {
  const { active = 0, limited = 0, stopped = 0, total = 0 } = values

  const totalCountContent = (
    <div className={classes.totalRow}>
      {link && total > 0 ? (
        <Link to={link} style={{ textDecoration: "none" }}>
          <span className={classes.totalRowWithLink}>
            <strong className={classes.countItem}>{total}</strong> {title.toLowerCase()}
          </span>
        </Link>
      ) : (
        <span>
          {total} {title.toLowerCase()}
        </span>
      )}
    </div>
  )

  return (
    <div className={classes.countWidgetBody}>
      {totalCountContent}
      {!onlyTotal && (
        <>
          <div>
            <span className={classes.countItem}>
              {active} {labels.active}
            </span>
          </div>
          <div>
            <span className={classes.countItem}>
              {limited} {labels.limited}
            </span>
          </div>
          <div>
            <span className={classes.countItem}>
              {stopped} {labels.stopped}
            </span>
          </div>
        </>
      )}
    </div>
  )
}

ServicesCountWidget.propTypes = {
  labels: PropTypes.object.isRequired,
  link: PropTypes.string,
  onlyTotal: PropTypes.bool,
  title: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  classes: PropTypes.object,
}

export default injectSheet(servicesCountWidgetStyles)(ServicesCountWidget)
