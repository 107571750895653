import React from "react"
import PropTypes from "prop-types"
import injectSheet from "react-jss"
import { graphql, compose } from "react-apollo"

import { hasRole, role } from "../core"

import CountWidget from "../components/dashboard/CountWidget"
import ServicesWidget from "../components/dashboard/ServicesWidget"
import RssWidget from "../components/dashboard/RssWidget"
import Top5Data from "../components/dashboard/Top5Widget"
import MainDashboardLayout from "../components/dashboard/layouts/MainDashboardLayout"
import ButtonLink from "../components/common/ButtonLink"
import { ProviderDashboardBreadcrumb } from "../components/common/breadcrumbs/builders/providerBreadcrumbs"
import Loader from "../components/common/Loader"
import { DashboardPanel } from "../components/dashboard/dashboardPanel"
import { CallcenterPromotion } from "../components/dashboard/CallcenterPromotion"
import Page from "../components/_layout/Page"
import { ErrorAlert } from "../components/common/Alert"
import Icon from "../components/common/Icon"

import CustomersCount from "../graphql/reports/customersCountQuery.graphql"
import RequestsCount from "../graphql/reports/requestsCountQuery.graphql"
import containersQuery from "../graphql/containers/containersQuery.graphql"

import { useI18n } from "../internationalization/i18n"

const styles = theme => ({
  iconDiagnostics: {
    fontWeight: 200,
    fontSize: "4rem",
    color: theme.color.grey600,
  },
})

const Dashboard = ({ cookies: { user }, voipContainers, classes }) => {
  const msg = useI18n().messages.dashboard
  const allowServices = hasRole(user.roles, [role.SUPERADMIN, role.ADMIN, role.REALIZATION])
  const allowDiagnostics = hasRole(user.roles, [role.SUPERADMIN, role.ADMIN, role.SUPPORT])

  const toolbar = (
    <>
      <ButtonLink icon="user" text={msg.customerNew} to="/customerForm" id="newCustomer" />
      <ButtonLink
        icon="grid"
        text={msg.serviceNew}
        to="/serviceSelection"
        disabled={!allowServices}
        id="newService-btn"
      />
    </>
  )

  let content = null

  if (voipContainers && voipContainers.loading) content = <Loader />
  else if (voipContainers.error || !voipContainers)
    content = <ErrorAlert id="voipContainersQueryError" message={voipContainers.error || ""} />
  else {
    const hasIspHost = voipContainers.containers.some(i => i.host)
    content = (
      <MainDashboardLayout>
        <CountWidget
          id="customers"
          url="/customers"
          headerIcon="user"
          headerLabel={msg.customers}
          query={CustomersCount}
          queryType="customersCount"
          labels={msg.dashboardWidgetLabels}
          titleMsg={msg.counts.customers}
          variables={{ pathBuilder: () => "customers?hidden=false" }}
        />
        <DashboardPanel
          linkToDetail={hasIspHost && allowDiagnostics ? "/diagnostics" : ""}
          icon="chart-bar"
          title={msg.diagnostics}
          id="diagnostics"
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Icon className={classes.iconDiagnostics} iconType="chart-bar" size={50} />
          </div>
        </DashboardPanel>
        <CountWidget
          id="requests"
          url="/requests?type=realization"
          headerIcon="shopping-cart"
          headerLabel={msg.requests}
          query={RequestsCount}
          queryType="requestsCount"
          labels={msg.dashboardWidgetLabels}
          titleMsg={msg.counts.requests}
          variables={{ state: ["processing", "new"].join() }}
        />
        <RssWidget />
        <ServicesWidget />
        <Top5Data />
      </MainDashboardLayout>
    )
  }

  return (
    <Page
      title={msg.title}
      isDashboard
      breadcrumbs={<ProviderDashboardBreadcrumb />}
      toolbar={toolbar}
    >
      <CallcenterPromotion />
      {content}
    </Page>
  )
}

Dashboard.propTypes = {
  voipContainers: PropTypes.object,
  cookies: PropTypes.object,
  classes: PropTypes.object,
}

Dashboard.mainClassName = "dashboard-content main-content"

export default compose(
  graphql(containersQuery, {
    name: "voipContainers",
    options: { variables: { type: "ISP" } },
  }),
)(injectSheet(styles)(Dashboard))
