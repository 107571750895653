import React from "react"
import { Icon } from "@ipex/storybook"
import { createUseStyles } from "react-jss"

const usePromotionStyles = createUseStyles(theme => ({
  wrapper: {
    backgroundColor: "rgb(255 237 155)",
    padding: "1rem",
    margin: "0.675rem 0",
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.5rem",

    "& > .header-icon": {
      marginRight: "0.5rem",
    },
  },
}))

const CallcenterPromotion = () => {
  const classes = usePromotionStyles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Icon className="header-icon" iconType="bell" />
        <h3>Call centrum zítřka - nyní i pro Vás!</h3>
      </div>
      <p>
        Pro ústředny verze <strong>4.15.0</strong> a vyšší je nyní k dispozici nové rozhraní call
        centra přinášející plno zajímavých vlastností. Kontaktujte své implementátory ohledně
        upgradu, nebo postupujte dle&nbsp;
        <a
          target="_blank"
          href="https://ipexas.atlassian.net/wiki/spaces/VD/pages/3654025226/Nov+rozhran+IPEX+Call+centrum"
        >
          manuálu
        </a>
        .
      </p>
    </div>
  )
}

export { CallcenterPromotion }
