import React from "react"
import { Query } from "react-apollo"
import PropTypes from "prop-types"
import injectSheet from "react-jss"

import { DashboardPanel } from "./dashboardPanel"

const countWidgetStyles = {
  countWidgetBody: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    textTransform: "lowercase",
    justifyContent: "center",
  },
  countSum: {
    fontSize: "3rem",
    lineHeight: "3.25rem",
  },
  countLabel: {
    fontSize: "0.875rem",
  },
}

export const returnTitle = (msg, count) => {
  if (count === undefined) return
  return count === 1 ? msg.one : count <= 4 && count > 0 ? msg.toFive : msg.more
}

export const usersCount = data => data && data.reduce((sum, item) => sum + item.count, 0)

const getCount = (
  data,
  filterDataFunc, // TODO:  get rid off this, when all report routes are ready
) => {
  if (data && Object.keys(data).length > 0) {
    return filterDataFunc
      ? data[Object.keys(data)[0]].filter(filterDataFunc).length
      : data[Object.keys(data)[0]].length
  }
}

const CountWidget = ({
  headerLabel,
  headerIcon,
  url,
  addUrl,
  titleMsg,
  query,
  queryType,
  variables,
  addonComponent,
  addonMessages,
  skip,
  id,
  filterDataFunc,
  classes,
}) => (
  <Query query={query} skip={skip} variables={variables}>
    {({ loading, error, data }) => {
      const Addon = addonComponent
      const count = queryType
        ? queryType === "usersCustomerCount" // TODO: simplify this, when all report routes are ready
          ? usersCount(data[queryType])
          : data && data[queryType] && data[queryType].count
        : getCount(data, filterDataFunc)
      return (
        <DashboardPanel
          linkToDetail={url}
          linkAddNew={addUrl}
          icon={headerIcon}
          title={headerLabel}
          id={id}
          loading={loading}
          error={error}
        >
          <div className={classes.countWidgetBody}>
            <span className={classes.countSum}>{count}</span>
            <span className={classes.countLabel}>{returnTitle(titleMsg, count)}</span>
            {Addon && <Addon data={data} msg={addonMessages} />}
          </div>
        </DashboardPanel>
      )
    }}
  </Query>
)

CountWidget.propTypes = {
  headerLabel: PropTypes.string,
  headerIcon: PropTypes.string,
  url: PropTypes.string,
  addUrl: PropTypes.string,
  title: PropTypes.string,
  titleMsg: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  variables: PropTypes.object,
  addonComponent: PropTypes.node,
  addonMessages: PropTypes.object,
  skip: PropTypes.bool,
  id: PropTypes.string,
  filterDataFunc: PropTypes.func,
  queryType: PropTypes.string,
  classes: PropTypes.object,
}

CountWidget.defaultProps = {
  skip: false,
}

export default injectSheet(countWidgetStyles)(CountWidget)
