/** Created by Filip Drgoň on 2019-01-03. */
import React from "react"
import { Query } from "react-apollo"
import rssQuery from "../../graphql/dashboard/rssQuery.graphql"
import moment from "moment"
import { DashboardPanel } from "./dashboardPanel"
import { useI18n } from "../../internationalization/i18n"

const RssWidget = () => {
  const msg = useI18n().messages.components.rssWidget

  return (
    <Query query={rssQuery} variables={{ amount: 10 }}>
      {({ loading, error, data }) => (
        <DashboardPanel
          linkToDetail="http://www.mobil21.cz/blog/"
          icon="bell"
          title={msg.title}
          id="rss"
          loading={loading}
          error={error}
          centerContent={false}
        >
          <div style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
            {data.rss &&
              data.rss.map(post => (
                <div key={post.link} style={{ marginBottom: "0.25rem" }}>
                  <a
                    href={post.link}
                    target="_blank"
                    style={{ textDecoration: "none", color: "inherit", lineHeight: 1 }}
                  >
                    <h4>
                      <b>{moment(post.date).format("D. M. YYYY")}</b>
                    </h4>
                    <span style={{ fontSize: "0.875rem" }}>{post.title}</span>
                  </a>
                </div>
              ))}
          </div>
        </DashboardPanel>
      )}
    </Query>
  )
}

RssWidget.propTypes = {}
RssWidget.defaultProps = {}

export default RssWidget
