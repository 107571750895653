import React from "react"
import PropTypes from "prop-types"
import injectSheet from "react-jss"

import { isServer } from "../../../core"

const mainDashboardStyles = {
  dashboardMain: {
    display: "grid",
    gridGap: "0.675rem",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "repeat(3, auto) 1fr 18rem 18rem",
    "& > div:nth-child(1)": { gridRow: 1 },
    "& > div:nth-child(2)": { gridRow: 2 },
    "& > div:nth-child(3)": { gridRow: 3 },
    "& > div:nth-child(4)": { gridRow: 4 },
    "& > div:nth-child(5)": { gridRow: 5 },
    "& > div:nth-child(6)": { gridRow: 6 },
  },
  "@media screen and (min-width: 940px)": {
    dashboardMain: {
      gridTemplateColumns: "32% 1fr 32%",
      gridTemplateRows: "19rem 1fr 19rem",
      "& > div:nth-child(1)": { gridColumn: 1, gridRow: 1 },
      "& > div:nth-child(2)": { gridColumn: 2, gridRow: 1 },
      "& > div:nth-child(3)": { gridColumn: 3, gridRow: 1 },
      "& > div:nth-child(4)": { gridColumn: "1 / span 3", gridRow: 2 },
      "& > div:nth-child(5)": { gridColumn: "1 / span 2", gridRow: 3 },
      "& > div:nth-child(6)": { gridColumn: 3, gridRow: 3 },
    },
  },
  "@media screen and (min-width: 1240px)": {
    dashboardMain: {
      gridTemplateColumns: "20% 20% 20% 1fr",
      gridTemplateRows: "19rem 19rem",
      "& > div:nth-child(1)": { gridColumn: 1, gridRow: 1 },
      "& > div:nth-child(2)": { gridColumn: 2, gridRow: 1 },
      "& > div:nth-child(3)": { gridColumn: 3, gridRow: 1 },
      "& > div:nth-child(4)": { gridColumn: 4, gridRow: "1 / span 2" },
      "& > div:nth-child(5)": { gridColumn: "1 / span 2", gridRow: 2 },
      "& > div:nth-child(6)": { gridColumn: 3, gridRow: 2 },
    },
  },
}

const MainDashboardLayout = ({ children, classes }) => {
  if (isServer()) return null
  return <div className={classes.dashboardMain}>{children}</div>
}

MainDashboardLayout.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.object,
}

export default injectSheet(mainDashboardStyles)(MainDashboardLayout)
