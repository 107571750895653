import React from "react"
import PropTypes from "prop-types"
import injectSheet from "react-jss"
import Link from "next/link"
import DashboardPanelHeader from "./DahboardPanelHeader"
import Loader from "../../common/Loader"
import { ErrorAlert } from "../../common/Alert"
import { useI18n } from "../../../internationalization/i18n"

const dashboardPanelStyles = themes => ({
  panel: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
    padding: "0 0.25rem",
    background: themes.color.white,
  },
  panelBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: props => (props.centerContent ? "center" : ""),
    flex: "1 1 auto",
    padding: "1rem",
    minHeight: ({ minHeight }) => minHeight || "15rem",
  },
})

const DashboardPanel = ({
  linkToDetail,
  linkAddNew,
  icon,
  title,
  id,
  children,
  loading,
  error,
  classes,
  style,
  fullClick,
}) => {
  const msg = useI18n().messages.common.error

  let content = null
  if (loading) {
    content = <Loader isWidget isSmall />
  } else if (error) {
    content = <ErrorAlert id={`${id}_widgetError`} message={msg.loadingDataFailed} />
  } else {
    content = children
  }

  const panel = (
    <div className={classes.panel} style={style}>
      <DashboardPanelHeader
        title={title}
        icon={icon}
        linkToDetail={linkToDetail}
        linkAddNew={linkAddNew}
        id={id}
        fullClick={fullClick}
      />
      <div className={classes.panelBody}>{content}</div>
    </div>
  )

  return fullClick ? <Link href={linkToDetail}>{panel}</Link> : panel
}

DashboardPanel.propTypes = {
  linkToDetail: PropTypes.string,
  linkAddNew: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  id: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  minHeight: PropTypes.string,
  centerContent: PropTypes.bool,
  style: PropTypes.object,
  fullClick: PropTypes.bool,
}

DashboardPanel.defaultProps = {
  linkToDetail: null,
  loading: false,
  error: null,
  centerContent: true,
  fullClick: false,
}

export default injectSheet(dashboardPanelStyles)(DashboardPanel)
