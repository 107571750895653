import React from "react"
import Link from "next/link"
import PropTypes from "prop-types"

import { ButtonIcon } from "./form"

const ButtonLink = ({ to, query, text, icon, id, disabled, ...props }) =>
  disabled ? (
    <ButtonIcon disabled={!!disabled} id={id} data-cy={id} iconType={icon} iconRight {...props}>
      {text}
    </ButtonIcon>
  ) : (
    <Link
      href={{
        pathname: to,
        query: query,
      }}
    >
      <a type="button">
        <ButtonIcon id={id} data-cy={id} iconType={icon} iconRight {...props}>
          {text}
        </ButtonIcon>
      </a>
    </Link>
  )

ButtonLink.defaultProps = {
  disabled: false,
}

ButtonLink.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.string, // fas icon class
  id: PropTypes.string,
  query: PropTypes.object,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  to: PropTypes.string,
}

export default ButtonLink
